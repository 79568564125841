import { useMutation, useQuery, useQueryClient } from "react-query";
import { getCategoryList } from "../../services/categories";
import {
  deleteProduct,
  getProductList,
  searchProducts,
} from "../../services/products";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config";
import ProductImage from "../../components/Product/ProductImage";
import { useSnackbar } from "notistack";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { convertDate } from "../../utils";

const perPageOptions = [10, 20, 30];

const DeleteProductModal = ({ show, handleClose, handleDelete }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Product</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleDelete}
        >
          Confirm
        </button>
        <button type="button" className="btn btn-danger" onClick={handleClose}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

function Products() {
  const [categoryId, setCategoryId] = useState(null);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [productList, setProductList] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    criteriaMode: "all",
  });

  useEffect(() => {
    const categoryId = sessionStorage.getItem("category_id");
    setCategoryId(categoryId);
  }, []);

  useEffect(() => {
    // Save scroll position before page is unloaded
    window.addEventListener("scroll", () => {
      sessionStorage.setItem("scrollPosition", window.scrollY);
    });

    // Cleanup
    return () => {
      window.removeEventListener("beforeunload", () => {
        sessionStorage.setItem("scrollPosition", window.scrollY);
      });
    };
  }, []);

  useEffect(() => {
    // Restore scroll position
    if (sessionStorage.getItem("scrollPosition")) {
      window.scrollTo(0, sessionStorage.getItem("scrollPosition"));
    }
  });

  const categoryListQuery = useQuery({
    queryKey: ["categoryList"],

    queryFn: getCategoryList,
    onSuccess(data) {
      if (!categoryId) {
        setCategoryId(data[0].category_id);
      }
    },
  });

  const categoryList = categoryListQuery.data || [];

  const productListQuery = useQuery({
    queryKey: ["productList", categoryId, currentPage, perPage],
    enabled: search === "",
    queryFn: () =>
      getProductList({
        categoryId:
          categoryId === null ? categoryList[0].category_id : categoryId,
        page: currentPage,
        perPage,
      }),
    onSuccess(data) {
      setProductList(data);
    },
  });

  // productList = productListQuery.data || [];

  const searchProductMutation = useMutation({
    mutationFn: searchProducts,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess(data) {
      setProductList(data);
    },
  });

  const deleteProductMutation = useMutation({
    mutationFn: deleteProduct,
    onError(error) {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    onSuccess(data) {
      enqueueSnackbar("Delete Product success!", { variant: "success" });
      queryClient.invalidateQueries("productList");
      setShowDeleteModal(false);
    },
  });

  const hasProducts = productList.length;

  const handleSelectCategory = (event) => {
    const id = event.target.value;
    setCategoryId(id);
    sessionStorage.setItem("category_id", id);
  };

  const handleIncreasePage = () => {
    if (hasProducts) {
      if (search !== "") {
        searchProductMutation.mutate({
          categoryId:
            categoryId === null ? categoryList[0].category_id : categoryId,
          page: currentPage + 1,
          perPage,
          keyword: search,
        });
      }
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleDecreasePage = () => {
    if (currentPage > 1) {
      if (search !== "") {
        searchProductMutation.mutate({
          categoryId:
            categoryId === null ? categoryList[0].category_id : categoryId,
          page: currentPage - 1,
          perPage,
          keyword: search,
        });
      }
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleChoosePage = (page) => {
    if (hasProducts || page < currentPage) {
      if (search !== "") {
        searchProductMutation.mutate({
          categoryId:
            categoryId === null ? categoryList[0].category_id : categoryId,
          page,
          perPage,
          keyword: search,
        });
      }
      setCurrentPage(page);
    }
  };

  const handleSetPerPage = (event) => {
    const perPage = event.target.value;
    if (search !== "") {
      searchProductMutation.mutate({
        categoryId:
          categoryId === null ? categoryList[0].category_id : categoryId,
        page: currentPage,
        perPage,
        keyword: search,
      });
    }
    setPerPage(perPage);
  };

  const handleRedirectAddProduct = () => {
    navigate(config.routes.addProduct);
  };

  const handleShowDetail = (id) => {
    navigate(`${config.routes.product}/${id}`);
  };

  const handleShowDeleteModal = (id) => {
    setShowDeleteModal(true);
    setDeleteProductId(id);
  };

  const handleDeleteProduct = () => {
    deleteProductMutation.mutate(deleteProductId);
  };

  const handleSearch = () => {
    searchProductMutation.mutate({
      categoryId:
        categoryId === null ? categoryList[0].category_id : categoryId,
      page: currentPage,
      perPage,
      keyword: search,
    });
  };

  return (
    <div>
      <h3 className="text-center">Product List</h3>
      <div className="d-flex">
        <div className="d-flex align-items-center p-4">
          <label htmlFor="category" className="mx-2">
            Category:
          </label>
          <select
            style={{ width: "300px" }}
            className="form-select mw-400"
            onChange={handleSelectCategory}
            value={categoryId}
            id="category"
          >
            {categoryList.map((op) => (
              <option key={op.category_id} value={op.category_id}>
                {op.category_name}
              </option>
            ))}
          </select>
        </div>
        <form
          className="input-group p-4 d-flex align-items-center"
          onSubmit={handleSubmit(handleSearch)}
          style={{ position: "relative" }}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Search Products"
            aria-label="Search Products"
            aria-describedby="basic-addon2"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <i
            className="bi bi-x-circle"
            style={{
              position: "absolute",
              right: "100px",
              cursor: "pointer",
              zIndex: "1",
            }}
            onClick={() => setSearch("")}
          ></i>
          <div className="input-group-append">
            <button className="btn btn-primary" type="submit">
              {searchProductMutation.isLoading && (
                <span>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  &nbsp;
                </span>
              )}
              Search
            </button>
          </div>
        </form>
      </div>
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleRedirectAddProduct}
        >
          Add Product
        </button>
        <select
          style={{ width: "100px" }}
          className="form-select mw-400"
          onChange={handleSetPerPage}
          value={perPage}
          id="perPage"
        >
          {perPageOptions.map((op) => (
            <option key={op} value={op}>
              {op}
            </option>
          ))}
        </select>
      </div>
      <div>
        {productListQuery.isLoading ? (
          "Loading"
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Part Number</th>
                <th scope="col">Description</th>
                <th scope="col">Photo</th>
                <th scope="col">Last modified at</th>
                <th scope="col">Modified by</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {productList.map((product, index) => (
                <tr key={product.product_id}>
                  <th scope="row">{(currentPage - 1) * perPage + index + 1}</th>
                  <td onClick={() => handleShowDetail(product.product_id)}>
                    <Link dangerouslySetInnerHTML={{
                      __html: `${product.part_number}`,
                    }}></Link>
                  </td>
                  <td
                    dangerouslySetInnerHTML={{
                      __html: `${product.description_short}`,
                    }}
                  ></td>
                  <td>
                    <ProductImage src={product.photo_small_url} />
                  </td>
                  <td>{convertDate(product.updated_at)}</td>
                  <td>{product.updated_by}</td>
                  <td>
                    <i
                      className="bi bi-trash3"
                      onClick={() => handleShowDeleteModal(product.product_id)}
                      style={{ cursor: "pointer" }}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li
            className={`page-item ${currentPage === 1 && "disabled"}`}
            onClick={handleDecreasePage}
          >
            <a className="page-link " href="#">
              Previous
            </a>
          </li>
          <li
            class="page-item"
            onClick={() =>
              handleChoosePage(currentPage === 1 ? 1 : currentPage - 1)
            }
          >
            <a class={`page-link ${currentPage === 1 && "active"}`} href="#">
              {currentPage === 1 ? "1" : currentPage - 1}
            </a>
          </li>
          <li
            class="page-item"
            onClick={() =>
              handleChoosePage(currentPage === 1 ? 2 : currentPage)
            }
          >
            <a class={`page-link ${currentPage !== 1 && "active"}`} href="#">
              {currentPage === 1 ? "2" : currentPage}
            </a>
          </li>
          <li
            class="page-item"
            onClick={() =>
              handleChoosePage(currentPage === 1 ? 3 : currentPage + 1)
            }
          >
            <a class={`page-link ${!hasProducts && "disabled"}`} href="#">
              {currentPage === 1 ? "3" : currentPage + 1}
            </a>
          </li>
          <li
            class={`page-item ${!hasProducts && "disabled"}`}
            onClick={handleIncreasePage}
          >
            <a class="page-link" href="#">
              Next
            </a>
          </li>
        </ul>
      </nav>
      <DeleteProductModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={handleDeleteProduct}
      />
    </div>
  );
}

export default Products;
